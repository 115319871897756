import VueApp from '@/main'
import axios from 'axios'
import jwtConfig from '@core/auth/jwt/jwtDefaultConfig'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const vueAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // headers: { 'X-Custom-Header': 'foobar' },
})

vueAxios.defaults.headers.post['Content-Type'] = 'application/json'
vueAxios.defaults.headers.get.accepts = 'application/json'

const parseError = error => {
  let errorText = ''

  // The server response was received with a status code that falls out of the range of 2xx
  // OR
  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  if (error.response || error.request) {
    const mode = error.response ? 'response' : 'request'

    if (!error[mode].data) {
      errorText = error[mode].statusText
    } else if (typeof error[mode].data === 'string') {
      errorText = error[mode].data
    } else if (error[mode].data.description) {
      errorText = error[mode].data.description
    } else if (error[mode].data.message) {
      errorText = error[mode].data.message
    } else if (error[mode].data.status) {
      errorText = error[mode].data.status
    }
  } else if (error.message) {
    // Something happened in setting up the request that triggered an error
    errorText = error.message
  } else {
    errorText = error
  }

  if (
    errorText === '' ||
    (typeof errorText === 'object' && Object.keys(errorText).length === 0)
  ) {
    return 'Something went wrong!'
  }

  // Capitalize first letter
  errorText = (str => {
    if (typeof str !== 'string') return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  })(errorText)

  return errorText
}

vueAxios.throwError = error => Promise.reject(parseError(error))

vueAxios.handleError = error => parseError(error)

let pendingCalls = 0

// Progress bar loader on ajax request
vueAxios.interceptors.request.use(config => {
  if (!pendingCalls) {
    VueApp.$Progress.start()
  }
  pendingCalls += 1
  return config
})

// Add a response interceptor
vueAxios.interceptors.response.use(
  res => {
    pendingCalls -= 1
    if (!pendingCalls) {
      VueApp.$Progress.finish()
    }
    return res
  },
  error => {
    // Show failed progress bar regardless if its the last call
    pendingCalls -= 1
    VueApp.$Progress.fail()

    if (
      VueApp.$router.currentRoute.meta.resource !== 'Auth' &&
      [401, 403].includes(error.response.status)
    ) {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(jwtConfig.storageTokenKeyName)
      localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      window.location.href = '/login'
    }

    return Promise.reject(error)
  },
)

export default vueAxios
