import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData') &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const getUserId = () => JSON.parse(localStorage.getItem('userData')).id

export const getSubscription = () =>
  JSON.parse(localStorage.getItem('userData')).userGroup

export const getSubscriptionTypes = () => [
  { id: 'MONTHLY', title: 'MONTHLY' },
  { id: 'YEARLY', title: 'YEARLY' },
]

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRights = () => {
  const user = getUserData()
  const rights = []
  if (user) {
    if (user.userGroup) {
      user.userGroup.userGroupRights.forEach(right => {
        rights.push(right.authority)
      })
    }
    if (user.secondaryGroups) {
      user.secondaryGroups.forEach(group => {
        group.userGroupRights.forEach(right => {
          if (rights.indexOf(right.authority) === -1) {
            rights.push(right.authority)
          }
        })
      })
    }
  }

  return rights
}

export const isRoleType = roleType => {
  const user = getUserData()
  return user && user.userGroup && user.userGroup.title === roleType
}

export const isAdmin = () => isRoleType('ADMINS')

export const isUser = () => isRoleType('USERS')

export const canEditSubscription = () =>
  getUserRights().includes('Users_Subscriptions_Edit')

export const getHomeRouteForLoggedInUser = () => '/'
