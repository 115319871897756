import Vue from 'vue'
import VueRouter from 'vue-router'

import congressScreens from '@/router/congressScreens'

import {
  getUserRights,
  isUserLoggedIn,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      alias: '/dashboard',
      component: () => import('@/views/dashboard/Ecommerce.vue'),
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/account-settings/AccountSettings.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/change-log',
      name: 'changelog',
      component: () => import('@/views/changelog/Changelog.vue'),
      meta: {
        pageTitle: 'App Version',
        breadcrumb: [
          {
            text: 'Changelog',
            active: true,
          },
        ],
      },
    },

    {
      path: '/announcements/:categoryID?',
      name: 'announcements',
      component: () =>
        import(
          '@/views/announcements/announcements-list/AnnouncementsList.vue'
        ),
    },

    {
      path: '/notifications',
      name: 'notifications',
      component: () =>
        import(
          '@/views/notifications/notifications-list/NotificationsList.vue'
        ),
      meta: {
        pageTitle: 'Notifications',
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
          },
        ],
      },
    },

    {
      path: '/announcement/:id',
      name: 'announcement-single',
      component: () =>
        import(
          '@/views/announcements/announcements-single/AnnouncementsSingle.vue'
        ),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Blog',
            to: '/announcements',
          },
        ],
      },
    },
    {
      path: '/congresses/list',
      name: 'congresses-list',
      component: () =>
        import(
          '@/views/congress/congresses/congresses-list/CongressesList.vue'
        ),
      meta: {
        authority: 'Congress_Management_Congress_View',
      },
    },
    {
      path: '/congresses/view/:id',
      name: 'congress-view',
      component: () =>
        import(
          '@/views/congress/congresses/congresses-view/CongressesView.vue'
        ),
      meta: {
        authority: 'Congress_Management_Congress_Edit',
      },
    },
    ...congressScreens,

    {
      path: '/sponsors/list',
      name: 'sponsors-list',
      component: () =>
        import('@/views/sponsors/sponsors-list/SponsorsList.vue'),
      meta: {
        authority: 'General_Sponsor_View',
      },
    },
    {
      path: '/sponsors/edit/:id?',
      name: 'sponsors-edit',
      component: () =>
        import('@/views/sponsors/sponsors-edit/SponsorsEdit.vue'),
      meta: {
        authority: 'General_Sponsor_Edit',
      },
    },
    {
      path: '/persons/list',
      name: 'persons-list',
      component: () => import('@/views/persons/persons-list/PersonsList.vue'),
      meta: {
        authority: 'Users_Participant_View',
      },
    },
    {
      path: '/persons/edit/:id?',
      name: 'persons-edit',
      component: () => import('@/views/persons/persons-edit/PersonsEdit.vue'),
      meta: {
        authority: 'Users_Participant_Edit',
      },
    },
    {
      path: '/companies',
      name: 'companies-list',
      component: () =>
        import('@/views/companies/companies-list/CompanyList.vue'),
      meta: {
        authority: 'Users_Company_View',
      },
    },
    {
      path: '/companies/edit/:id?',
      name: 'companies-edit',
      component: () =>
        import('@/views/companies/companies-edit/CompanyEdit.vue'),
      meta: {
        authority: 'Users_Company_Edit',
      },
    },
    {
      path: '/vote-categories/list',
      name: 'vote-categories-list',
      component: () => import('@/views/vote-categories/List.vue'),
      meta: {
        authority: 'Poll_Management_Poll_View',
      },
    },
    {
      path: '/votes/list',
      name: 'votes-list',
      component: () => import('@/views/votes/List.vue'),
      meta: {
        authority: 'Poll_Management_Poll_View',
      },
    },
    {
      path: '/votes/edit/:id?',
      name: 'votes-edit',
      component: () => import('@/views/votes/Edit.vue'),
      meta: {
        authority: 'Poll_Management_Poll_Edit',
      },
    },
    {
      path: '/votes/view/:id',
      name: 'votes-view',
      component: () => import('@/views/votes/View.vue'),
      meta: {
        authority: 'Poll_Management_Poll_View',
      },
    },
    {
      path: '/voting/list',
      name: 'voting-list',
      component: () => import('@/views/votes/VotesGrid.vue'),
      meta: {
        authority: 'Poll_Management_Answer_View',
      },
    },
    {
      path: '/voting-responses/list/:id?',
      name: 'voting-responses-list',
      component: () => import('@/views/voting-responses/List.vue'),
      meta: {
        authority: 'Poll_Management_Answer_Edit',
      },
    },
    {
      path: '/voting-responses/:pid',
      name: 'voting-responses-edit',
      component: () => import('@/views/voting-responses/Edit.vue'),
      meta: {
        authority: 'Poll_Management_Answer_Edit',
      },
    },
    {
      path: '/questionnaire-categories/list',
      name: 'questionnaire-categories-list',
      component: () => import('@/views/questionnaire-categories/List.vue'),
      meta: {
        authority: 'Questionnaire_Management_Category_View',
      },
    },
    {
      path: '/questionnaire-centers/list',
      name: 'questionnaire-centers-list',
      component: () => import('@/views/questionnaire-centers/List.vue'),
      meta: {
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
    },
    {
      path: '/questionnaire-units/list',
      name: 'questionnaire-units-list',
      component: () => import('@/views/questionnaire-units/List.vue'),
      meta: {
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
    },
    {
      path: '/questionnaires/list',
      name: 'questionnaires-list',
      component: () => import('@/views/questionnaires/List.vue'),
      meta: {
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
    },
    {
      path: '/questionnaires/edit/:id?',
      name: 'questionnaires-edit',
      component: () => import('@/views/questionnaires/Edit.vue'),
      meta: {
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
    },
    {
      path: '/submissions/list',
      name: 'submissions-list',
      component: () => import('@/views/questionnaires/QuestionnairesGrid.vue'),
      meta: {
        authority: 'Questionnaire_Management_Answer_View',
      },
    },
    {
      path: '/answers/list/:id?',
      name: 'answers-list',
      component: () => import('@/views/questionnaire-answers/List.vue'),
      meta: {
        authority: 'Questionnaire_Management_Answer_Edit',
      },
    },
    {
      path: '/answers/:qid?/edit/:id?',
      name: 'answers-edit',
      component: () => import('@/views/questionnaire-answers/Edit.vue'),
      meta: {
        authority: 'Questionnaire_Management_Answer_Edit',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
    },
    {
      path: '/invoice/list',
      name: 'invoice-list',
      component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
    },
    {
      path: '/invoice/preview/:id',
      name: 'invoice-preview',
      component: () =>
        import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
      path: '/new-payment',
      name: 'payment',
      component: () => import('@/views/invoice/payment/EpayRedirect.vue'),
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/subscriptions/SubscriptionsGrid.vue'),
      // meta: {
      //   authority: 'Users_User_View',
      // },
    },
    {
      path: '/announcements-admin',
      name: 'announcements-list',
      component: () =>
        import(
          '@/views/announcements-admin/announcements-list/AnnouncementsList.vue'
        ),
      meta: {
        authority: 'General_Announcement_View',
      },
    },
    {
      path: '/announcements/edit/:id?',
      name: 'announcements-edit',
      component: () =>
        import(
          '@/views/announcements-admin/announcements-edit/AnnouncementsEdit.vue'
        ),
      meta: {
        authority: 'General_Announcement_Edit',
      },
    },
    {
      path: '/post-categories',
      name: 'post-categories-list',
      component: () =>
        import(
          '@/views/post-categories/post-categories-list/PostCategoriesList.vue'
        ),
      meta: {
        authority: 'General_Post_Category_View',
      },
    },
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/users/users-list/UsersList.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/users/users-view/UsersView.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/edit/:id?',
      name: 'users-edit',
      component: () => import('@/views/users/users-edit/UsersEdit.vue'),
      meta: {
        authority: 'Users_User_Edit',
      },
    },
    {
      path: '/user-groups',
      name: 'user-groups-list',
      component: () =>
        import('@/views/user-groups/user-groups-list/UserGroupsList.vue'),
      meta: {
        authority: 'Users_User_Group_View',
      },
    },
    {
      path: '/user-groups/edit/:id?',
      name: 'user-groups-edit',
      component: () =>
        import('@/views/user-groups/user-groups-edit/UserGroupsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/user-requests',
      name: 'user-requests-list',
      component: () =>
        import('@/views/user-requests/user-requests-list/UserRequestsList.vue'),
      meta: {
        authority: 'Users_User_Request_View',
      },
    },
    {
      path: '/user-requests/edit/:id?',
      name: 'user-requests-edit',
      component: () =>
        import('@/views/user-requests/user-requests-edit/UserRequestsEdit.vue'),
      meta: {
        authority: 'Users_User_Request_Edit',
      },
    },
    {
      path: '/venues/list',
      name: 'venues-list',
      component: () => import('@/views/venues/venues-list/VenuesList.vue'),
      meta: {
        authority: 'General_Venue_View',
      },
    },
    {
      path: '/venues/edit/:id?',
      name: 'venues-edit',
      component: () => import('@/views/venues/venues-edit/VenuesEdit.vue'),
      meta: {
        authority: 'General_Venue_Edit',
      },
    },
    {
      path: '/professions',
      name: 'professions-list',
      component: () => import('@/views/professions/List.vue'),
      meta: {
        authority: 'General_Profession_View',
      },
    },
    // {
    //   path: '/persons/list',
    //   name: 'persons-list',
    //   component: () => import('@/views/persons/persons-list/PersonsList.vue'),
    //   meta: {
    //     authority: 'Users_Participant_View',
    //   },
    // },
    // {
    //   path: '/persons/edit/:id?',
    //   name: 'persons-edit',
    //   component: () => import('@/views/persons/persons-edit/PersonsEdit.vue'),
    //   meta: {
    //     authority: 'Users_Participant_Edit',
    //   },
    // },
    {
      path: '/topics',
      name: 'topics-list',
      component: () => import('@/views/topics/List.vue'),
      meta: {
        authority: 'General_Topic_View',
      },
    },
    {
      path: '/specialties',
      name: 'specialties-list',
      component: () => import('@/views/specialties/List.vue'),
      meta: {
        authority: 'General_Specialty_View',
      },
    },
    {
      path: '/registration-types',
      name: 'registration-types-list',
      component: () => import('@/views/registration-types/List.vue'),
      meta: {
        authority: 'General_Registration_Type_View',
      },
    },
    {
      path: '/registration-periods',
      name: 'registration-periods-list',
      component: () => import('@/views/registration-periods/List.vue'),
      meta: {
        authority: 'General_Registration_Period_View',
      },
    },
    {
      path: '/session-types',
      name: 'session-types-list',
      component: () => import('@/views/session-types/List.vue'),
      meta: {
        authority: 'Session_Management_Session_Type_View',
      },
    },
    {
      path: '/prefectures',
      name: 'prefectures-list',
      component: () => import('@/views/prefectures/List.vue'),
      meta: {
        authority: 'General_Prefecture_View',
      },
    },
    {
      path: '/member-types',
      name: 'member-types-list',
      component: () => import('@/views/member-types/List.vue'),
      meta: {
        authority: 'General_MemberType_View',
      },
    },
    {
      path: '/hospital-types',
      name: 'hospital-types-list',
      component: () => import('@/views/hospital-types/List.vue'),
      meta: {
        authority: 'General_HospitalType_View',
      },
    },
    {
      path: '/hospitals',
      name: 'hospitals-list',
      component: () => import('@/views/hospitals/List.vue'),
      meta: {
        authority: 'General_Hospital_View',
      },
    },
    {
      path: '/clinics',
      name: 'clinics-list',
      component: () => import('@/views/clinics/List.vue'),
      meta: {
        authority: 'General_Hospital_View',
      },
    },
    {
      path: '/job-positions',
      name: 'job-positions-list',
      component: () => import('@/views/positions/List.vue'),
      meta: {
        authority: 'General_JobPosition_View',
      },
    },
    {
      path: '/working-groups',
      name: 'working-groups-list',
      component: () => import('@/views/working-groups/List.vue'),
      meta: {
        authority: 'General_WorkingGroup_View',
      },
    },
    {
      path: '/bank-accounts',
      name: 'bank-accounts-list',
      component: () => import('@/views/bank-accounts/List.vue'),
      meta: {
        authority: 'Users_Payment_View',
      },
    },
    {
      path: '/main-parameters',
      name: 'main-parameters-list',
      component: () => import('@/views/main-parameters/List.vue'),
      meta: {
        authority: 'General_MainParameter_View',
      },
    },
    {
      path: '/videos',
      name: 'videos-list',
      component: () => import('@/views/videos/List.vue'),
      meta: {
        authority: 'Congress_Management_Video_View',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:key',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/authentication/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const tauthority = to.matched[0].meta.authority

  if (!isLoggedIn && to.meta.resource !== 'Auth') {
    // Redirect to login if not logged in
    return next({ name: 'login' })
  }

  if (tauthority && !getUserRights().includes(tauthority)) {
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
