import moment from 'moment'
import i18n from '@/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
moment.locale(i18n.locale)
moment.updateLocale(moment.locale(), { invalidDate: '' })

const mixins = {
  methods: {
    isoDateTrimmed(time) {
      return moment(time).format('YYYY-MM-DDTHH:mm')
    },

    isoDate(time) {
      return this.momentFormatIso(moment(time))
    },

    momentFormatIso(mDate) {
      return mDate.format('YYYY-MM-DDTHH:mm:ss')
    },

    longDate(time) {
      return moment(time).format('dddd, D MMMM YYYY HH:mm')
    },

    mediumDate(time) {
      return moment(time).format('DD/MM/YYYY HH:mm')
    },

    shortDate(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    showToast(toastProps) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: toastProps,
      })
    },
    stripHtml(string) {
      if (!string) {
        return ''
      }
      return string.replace(/<\/?[^>]+>/gi, ' ')
    },
  },
}

export default mixins
