const meta = {
  authority: 'Congress_Management_Congress_View',
  congressView: true,
}

export default [
  {
    path: '/congresses/edit/:id?',
    name: 'congress-edit',
    component: () => import('@/views/congress/congresses/congresses-edit/CongressesEdit.vue'),
    meta,
  },
  {
    path: '/congresses/:id/registration-periods',
    name: 'congress-registration-periods',
    component: () => import('@/views/congress/registration-periods/registration-periods-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/sponsorship-packages',
    name: 'congress-sponsorship-packages',
    component: () => import('@/views/congress/sponsorship-packages/sponsorship-packages-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/services',
    name: 'congress-services',
    component: () => import('@/views/congress/services/services-list/ServicesList.vue'),
    meta,
  },
  {
    path: '/congresses/:id/videos',
    name: 'congress-videos',
    component: () => import('@/views/congress/videos/VideoGallery.vue'),
    meta,
  },
  {
    path: '/congresses/:id/photos',
    name: 'congress-photos',
    component: () => import('@/views/congress/photos/PhotoGallery.vue'),
    meta,
  },
  {
    path: '/congresses/:id/topics',
    name: 'congress-topics',
    component: () => import('@/views/congress/topics/topics-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/rooms',
    name: 'congress-rooms',
    component: () => import('@/views/congress/rooms/rooms-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/sponsors',
    name: 'congress-sponsors',
    component: () => import('@/views/congress/sponsors/sponsors-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/faculties',
    name: 'congress-faculties',
    component: () => import('@/views/congress/faculties/faculties-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/sessions',
    name: 'congress-sessions',
    component: () => import('@/views/congress/sessions/sessions-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/abstracts',
    name: 'congress-abstracts',
    component: () => import('@/views/congress/abstracts/abstracts-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/registrations',
    name: 'congress-registrations',
    component: () => import('@/views/congress/registrations/registrations-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/sessions-details',
    name: 'congress-emails-sessions-details',
    component: () => import('@/views/congress/emails/sessions-details/sessions-details-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/speaker-invitation',
    name: 'congress-emails-speaker-invitation',
    component: () => import('@/views/congress/emails/speaker-invitation/speaker-invitation-list.vue'),
    meta,
  },
  {
    path: '/congresses/:id/payment-reminder',
    name: 'congress-emails-payment-reminder',
    component: () => import('@/views/congress/emails/payment-reminder/payment-reminder-list.vue'),
    meta,
  },
]
